import React, { useState } from 'react';
import { submitLapTime, putQueue } from '../../helper/apiHelpers';
import { timeRegex, timestampToSeconds } from '../../helper/utils';

const LapTimeForm = ({ eventid, participant, queue_id }) => {
  const [fastestLap, setFastestLap] = useState('');
  const [lapError, setLapError] = useState('');

  const handleInputChange = (event) => {
    const { value } = event.target;
    setFastestLap(value);

    // Validate the lap time format
    if (timeRegex.test(value)) {
      setLapError('');
    } else {
      setLapError('El tiempo debe estar en el formato MM:SS.sss (ejemplo: 01:38.150)');
    }
  };

  const handleSubmitLap = async () => {
    if (lapError || !timeRegex.test(fastestLap)) {
      setLapError('El tiempo debe estar en el formato MM:SS.sss (ejemplo: 01:38.150)');
      return;
    }

    try {
      const lapTimeInSeconds = timestampToSeconds(fastestLap);
      const currentDate = new Date().toISOString();

      const lapData = {
        eventId: parseInt(eventid, 10),
        participantId: participant.participant_id,
        stationId: 99,
        lapNumber: 99,
        sessionId: 99,
        queueId: parseInt(queue_id, 10),
        lapTimeInSeconds: lapTimeInSeconds,
        date: currentDate,
        time: currentDate,
        teamid: 99,
        trackid: "Manual",
      };

      await submitLapTime(lapData);
      setFastestLap(''); // Clear the input after submission
    } catch (error) {
      setLapError(`Error: ${error.message}`);
    }
  };

  const handleFinish = async () => {
    try {
      await putQueue(queue_id, { status: 3 });
      alert('Piloto terminado');
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div className="lap-time-form bg-white text-black p-4 rounded-lg mb-6">
      <label className="text-xl mb-2 block">Ingrese el tiempo más rápido:</label>
      {lapError && (
        <div className="bg-red-500 text-white p-4 mb-4 rounded-md text-center text-lg">
          <p>⚠️ {lapError}</p>
          <p className="mt-2">Ejemplo: <strong>01:38.150</strong> (1 minuto, 38 segundos, 150 milisegundos)</p>
        </div>
      )}
      <input
        type="text"
        value={fastestLap}
        onChange={handleInputChange}
        placeholder="MM:SS.sss"
        className="bg-white text-black py-2 px-4 rounded-md mb-4 w-full"
      />
      <div className="flex justify-between">
        <button
          onClick={handleSubmitLap}
          className="bg-green-500 text-white py-2 px-4 rounded-md hover:scale-105 w-full mr-2"
          disabled={lapError}
        >
          Enviar
        </button>
        <button
          onClick={handleFinish}
          className="bg-red-500 text-white py-2 px-4 rounded-md hover:scale-105 w-full ml-2"
        >
          Terminar
        </button>
      </div>
    </div>
  );
};

export default LapTimeForm;
