import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { timeSince } from './helper/utils'; // Import the timeSince function

const Monitor = () => {
  const { eventid } = useParams(); // Extract eventid from the URL
  const [activatedUsers, setActivatedUsers] = useState([]);
  const [lapTimes, setLapTimes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastRefresh, setLastRefresh] = useState(null); // Track last refresh time

  const activatedUsersRef = useRef(activatedUsers);
  const lapTimesRef = useRef(lapTimes);

  // Function to fetch activated users and lap times without causing full UI re-render
  const fetchData = async () => {
    try {
      const [usersResponse, lapTimesResponse] = await Promise.all([
        fetch(`https://api4.gpesportsrd.com/timeattack/activate?eventid=${eventid}`),
        fetch(`https://api4.gpesportsrd.com/timeattack/laptime?eventId=${eventid}`) // Fetch lap times
      ]);

      const usersData = await usersResponse.json();
      const lapTimesData = await lapTimesResponse.json();

      if (usersData && usersData.data) {
        const sortedUsers = usersData.data.sort((a, b) => a.station_id - b.station_id);
        activatedUsersRef.current = sortedUsers; // Update reference instead of state directly
        setActivatedUsers([...sortedUsers]); // Use state to trigger data update without causing a full re-render
      } else {
        throw new Error('No activated users found.');
      }

      if (lapTimesData && lapTimesData.data) {
        const sortedLapTimes = lapTimesData.data.sort((a, b) => b.id - a.id); // Sort lap times by latest first
        lapTimesRef.current = sortedLapTimes;
        setLapTimes([...sortedLapTimes]);
      } else {
        throw new Error('No lap times found.');
      }

      // Set last refresh time to current time
      setLastRefresh(new Date().toLocaleTimeString());

      setLoading(false); // Stop loading after successful data fetch
    } catch (error) {
      console.error("Error fetching data:", error); // Log the error to see what's going wrong
      setError(error.message);
      setLoading(false); // Stop loading if there's an error
    }
  };

  // Function to get the most recent lap time for a station
  const getLastLapForStation = (stationId) => {
    const lapsForStation = lapTimesRef.current.filter(lap => lap.stationId === stationId);
    if (lapsForStation.length > 0) {
      const lastLap = lapsForStation[0]; // Get the most recent lap (already sorted)
      return `${timeSince(lastLap.date)} (L. ${lastLap.lapNumber} - ${lastLap.lapTimeInSeconds}s)`;
    } else {
      return 'N/A';
    }
  };

  // Fetch data and set an interval to auto-refresh only the data without UI re-render
  useEffect(() => {
    fetchData(); // Initial data fetch

    const interval = setInterval(() => {
      fetchData(); // Fetch new data every 15 seconds
    }, 15000); // 15000 milliseconds = 15 seconds

    return () => clearInterval(interval); // Clear interval when component unmounts
  }, [eventid]); // Dependency array, so it re-runs if eventid changes

  if (loading) {
    return <p>Loading data...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="monitor w-full h-full flex flex-col items-center mt-10">
      <h1 className="font-formula1-display-regular text-3xl text-white mb-8">
        Monitor de Usuarios Activados {lastRefresh && <span className="text-sm text-gray-400">Última actualización: {lastRefresh}</span>}
      </h1>
      
      {/* Activated Users Table */}
      <table className="table-auto text-white border-collapse mb-8">
        <thead>
          <tr className="bg-gray-800">
            <th className="px-4 py-2">Nombre</th>
            <th className="px-4 py-2">Estación</th>
            <th className="px-4 py-2">Hace cuánto tiempo</th> {/* Column for created_at */}
            <th className="px-4 py-2">Activado hace</th> {/* Column for activated_at */}
            <th className="px-4 py-2">Último tiempo</th> {/* New column for last lap times */}
          </tr>
        </thead>
        <tbody>
          {activatedUsersRef.current.map((user) => (
            <tr key={user.id} className="bg-gray-700 hover:bg-gray-600">
              <td className="border px-4 py-2">{user.name}</td>
              <td className="border px-4 py-2">{user.station_id}</td>
              {/* Use timeSince for both created_at and activated_at */}
              <td className="border px-4 py-2">{timeSince(user.created_at)}</td> {/* Time since created_at */}
              <td className="border px-4 py-2">{user.activated_at ? timeSince(user.activated_at) : "N/A"}</td> {/* Time since activated_at */}
              <td className="border px-4 py-2">{getLastLapForStation(user.station_id)}</td> {/* Last lap for this station */}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Lap Times Table */}
      <h1 className="font-formula1-display-regular text-3xl text-white mb-8">Últimos Tiempos de Vuelta</h1>
      <table className="table-auto text-white border-collapse">
        <thead>
          <tr className="bg-gray-800">
            <th className="px-4 py-2">ID</th>
            <th className="px-4 py-2">Participante</th>
            <th className="px-4 py-2">Estación</th>
            <th className="px-4 py-2">Número de Vuelta</th>
            <th className="px-4 py-2">Tiempo de Vuelta (segundos)</th>
            <th className="px-4 py-2">Fecha</th>
          </tr>
        </thead>
        <tbody>
          {lapTimesRef.current.map((lap) => (
            <tr key={lap.id} className="bg-gray-700 hover:bg-gray-600">
              <td className="border px-4 py-2">{lap.id}</td>
              <td className="border px-4 py-2">{lap.participantId}</td>
              <td className="border px-4 py-2">{lap.stationId}</td>
              <td className="border px-4 py-2">{lap.lapNumber}</td>
              <td className="border px-4 py-2">{lap.lapTimeInSeconds}</td>
              <td className="border px-4 py-2">{new Date(lap.date).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Monitor;
