import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchParticipants, submitLapTime } from "./helper/apiHelpers";
import Autosuggest from "react-autosuggest";
import { timestampToSeconds } from './helper/utils';

const ManualTimeEntry = () => {
  const { eventid } = useParams();
  const [participants, setParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [fastestLap, setFastestLap] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("");

  useEffect(() => {
    const loadParticipants = async () => {
      try {
        const data = await fetchParticipants(eventid);
        setParticipants(data);
      } catch (error) {
        console.error("Error fetching participants:", error);
      }
    };
    loadParticipants();
  }, [eventid]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : participants.filter(
          (participant) =>
            participant.name?.toLowerCase().includes(inputValue) || // Check if name exists
            participant.email?.toLowerCase().includes(inputValue) || // Check if email exists
            participant.phone?.toLowerCase().includes(inputValue) // Check if phone exists
        );
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.name} ({suggestion.email}, {suggestion.phone})
    </div>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setSelectedParticipant(suggestion);
  };

  const inputProps = {
    placeholder: "Search by name, email, or phone",
    value: searchTerm,
    onChange: (event, { newValue }) => setSearchTerm(newValue),
  };



  const handleSubmitLap = async () => {
    if (!selectedParticipant) {
      alert("Please select a participant.");
      return;
    }

    const lapTimeInSeconds = timestampToSeconds(fastestLap);
    const currentDate = new Date().toISOString();

    const lapData = {
      eventId: parseInt(eventid, 10),
      participantId: selectedParticipant.id,
      stationId: 99,
      lapNumber: 99,
      sessionId: 99,
      queueId: selectedParticipant.queues[0]?.id || 0,
      lapTimeInSeconds: lapTimeInSeconds,
      date: currentDate,
      time: currentDate,
      teamid: 99,
      trackid: "Manual",
    };

    try {
      await submitLapTime(lapData);
      setSubmissionStatus("Lap time submitted successfully");
      setFastestLap("");
      setSelectedParticipant(null);
      setSearchTerm("");
    } catch (error) {
      setSubmissionStatus(`Error: ${error.message}`);
    }
  };

  return (
    <div className="w-full h-full flex flex-col justify-center items-center mt-20">
      <h1 className="mb-10 font-formula1-display-regular text-3xl text-white">
        Ingrese el tiempo más rápido manualmente
      </h1>
      <div className="w-full max-w-md">
        <label className="font-formula1-display-regular text-xl text-white mb-1">
          Buscar participante (nombre, email, teléfono):
        </label>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={onSuggestionSelected}
          theme={{
            input: "bg-white text-black py-2 px-4 rounded-md mb-4 w-full",
            suggestionsContainerOpen: "absolute z-10 bg-[#12131b] text-white",
            suggestion: "px-4 py-2 cursor-pointer",
            suggestionHighlighted: "bg-[#3a3a3a]",
          }}
        />

        {selectedParticipant && (
          <div className="text-white mb-4">
            <p>
              <strong>Nombre:</strong> {selectedParticipant.name}
            </p>
            <p>
              <strong>Email:</strong> {selectedParticipant.email}
            </p>
            <p>
              <strong>Teléfono:</strong> {selectedParticipant.phone}
            </p>
          </div>
        )}

        <label className="font-formula1-display-regular text-xl text-white mb-1">
          Ingrese el tiempo más rápido:
        </label>
        <input
          className="bg-white text-black py-2 px-4 rounded-md mb-4 w-full"
          placeholder="Enter fastest lap (e.g., 1:23.456)"
          type="text"
          name="fastestLap"
          value={fastestLap}
          onChange={(e) => setFastestLap(e.target.value)}
          required
        />
        <button
          className="font-formula1-display-regular bg-white text-black py-2 px-4 rounded-md hover:scale-105 w-full"
          onClick={handleSubmitLap}
        >
          Enviar
        </button>
        {submissionStatus && (
          <div className="mt-4 text-white">
            <p>{submissionStatus}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManualTimeEntry;
