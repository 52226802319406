// src/helper/utils.js

export const timeRegex = /^(\d{1,2})[^0-9](\d{2})[^0-9](\d{3})$/;


export const secondsToTimestamp = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60); // Get the minutes part
  const seconds = Math.floor(totalSeconds % 60); // Get the seconds part
  const milliseconds = Math.round((totalSeconds - Math.floor(totalSeconds)) * 1000); // Get the milliseconds part

  // Ensure two-digit format for minutes and seconds, and three digits for milliseconds
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  const formattedMilliseconds = String(milliseconds).padStart(3, '0');

  return `${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;
};


export const timestampToSeconds = (time) => {
  if (!timeRegex.test(time)) {
    throw new Error("Formato de tiempo no válido. El formato debe ser MM:SS.sss (ejemplo: 01:38.150).");
  }
  const match = time.match(timeRegex);
  const [_, minutes, seconds, milliseconds] = match;
  return parseInt(minutes, 10) * 60 + parseInt(seconds, 10) + parseInt(milliseconds, 10) / 1000;
};

// Format a date string to local timezone
export const formatDateToLocal = (isoDateString) => {
  const date = new Date(isoDateString);
  return date.toLocaleString('en-US', { 
    hour: 'numeric', 
    minute: 'numeric', 
    hour12: true, 
    day: 'numeric', 
    month: 'short', 
    year: 'numeric' 
  });
};

// Start a countdown timer
export const startCountdownTimer = (maxTime, setCountdown, setTurnFinished) => {
  const countdownTime = maxTime + 30;
  const interval = setInterval(() => {
    setCountdown((prevCountdown) => {
      if (prevCountdown === 1) {
        clearInterval(interval);
        setTurnFinished(true);
        return 0;
      }
      return prevCountdown - 1;
    });
  }, 1000);
  setCountdown(countdownTime);
};

// New timeSince utility to calculate the time difference dynamically using UTC
export const timeSince = (createdAt) => {
  const createdDate = new Date(createdAt);

  // Get the current UTC time
  const now = new Date(); // This is already in local time
  const currentUTC = new Date(now.toISOString()); // Convert to UTC

  // Calculate the difference in milliseconds
  const diffInMs = currentUTC - createdDate;

  const diffInMinutes = Math.floor(diffInMs / 60000); // Convert milliseconds to minutes
  const diffInHours = Math.floor(diffInMinutes / 60); // Convert minutes to hours
  const remainingMinutes = diffInMinutes % 60; // Remaining minutes

  if (diffInHours > 0) {
    return `${diffInHours} hora${diffInHours > 1 ? 's' : ''} y ${remainingMinutes} minuto${remainingMinutes > 1 ? 's' : ''}`;
  } else {
    return `${diffInMinutes} minuto${diffInMinutes !== 1 ? 's' : ''}`;
  }
};
