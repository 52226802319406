import React from "react";
import { BrowserRouter, Routes, Route, Link, useParams } from "react-router-dom";
import RegisterParticipant from "./components/RegisterParticipant/RegisterParticipant";
import FastestLap from "./FastestLap";
import ActivateParticipant from "./components/ActivateParticipant/ActivateParticipant";
import SdkartingActivate from "./SdkartingActivate";
import ManualTimeEntry from "./ManualTimeEntry";
import Monitor from "./Monitor"; // Import Monitor Component
import { TeamsProvider } from "./context/TeamsContext";

// Navigation Component
const Navigation = ({ eventid }) => {
  return (
    <nav className="text-white py-3 px-8 flex w-full justify-between font-formula1-display-regular shadow-xl">
      <div className="flex">
        <Link className="mr-6 hover:brightness-200 hover:scale-105" to="/">
          Home
        </Link>
        <Link
          className="hover:brightness-200 hover:scale-105"
          to={`/fastest-laps/${eventid}`}
        >
          Fastest Laps
        </Link>
        <Link
          className="hover:brightness-200 hover:scale-105"
          to={`/monitor/${eventid}`} // Add link to Monitor
        >
          Monitor
        </Link>
      </div>
      <Link
        className="hover:brightness-200 hover:scale-105"
        to={`/register/${eventid}`}
      >
        Register for Event {eventid}
      </Link>
      <Link
        className="hover:brightness-200 hover:scale-105"
        to={`/activate/${eventid}`}
      >
        Activate
      </Link>
      <Link
        className="hover:brightness-200 hover:scale-105"
        to={`/sdkartingactivate/${eventid}`}
      >
        SDKarting Activate
      </Link>
      <Link
        className="hover:brightness-200 hover:scale-105"
        to={`/manualtime/${eventid}`}
      >
        Manual Time Entry
      </Link>
    </nav>
  );
};

// Wrapper to handle navigation based on eventid
const NavigationWrapper = ({ children }) => {
  const { eventid } = useParams();
  return (
    <>
      <Navigation eventid={eventid} />
      {children}
    </>
  );
};

// Main App Component
function App() {
  return (
    <TeamsProvider> 
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                <NavigationWrapper>
                  <h1>Time Attack</h1>
                </NavigationWrapper>
              }
            />
            <Route
              path="/fastest-laps/:eventid"
              element={<FastestLap />}
            />
            <Route
              path="/register/:eventid"
              element={<RegisterParticipant />}
            />
            <Route
              path="/activate/:eventid"
              element={
                <NavigationWrapper>
                  <ActivateParticipant />
                </NavigationWrapper>
              }
            />
            <Route
              path="/activate/:eventid/:queue_id"
              element={
                <NavigationWrapper>
                  <ActivateParticipant />
                </NavigationWrapper>
              }
            />
            <Route
              path="/sdkartingactivate/:eventid"
              element={
                <NavigationWrapper>
                  <SdkartingActivate />
                </NavigationWrapper>
              }
            />
            <Route
              path="/manualtime/:eventid"
              element={
                <NavigationWrapper>
                  <ManualTimeEntry />
                </NavigationWrapper>
              }
            />
            <Route
              path="/monitor/:eventid" // Add a route for the Monitor
              element={
                <NavigationWrapper>
                  <Monitor /> 
                </NavigationWrapper>
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </TeamsProvider>
  );
}

export default App;
