import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ActivateForm from './ActivateForm';
import LapTimeForm from './LapTimeForm';
import { fetchParticipantDetails, fetchEventDetails, fetchLapTimes } from '../../helper/apiHelpers'; 
import { formatDateToLocal, secondsToTimestamp } from '../../helper/utils'; // Import secondsToTimestamp

const ActivateParticipant = () => {
  const { queue_id } = useParams();
  const [participant, setParticipant] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);
  const [isActivated, setIsActivated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [countdown, setCountdown] = useState(null);
  const [turnFinished, setTurnFinished] = useState(false);
  const [lapTimes, setLapTimes] = useState([]);  // State to store lap times

  useEffect(() => {
    const fetchData = async () => {
      try {
        const participantData = await fetchParticipantDetails(queue_id);
        setParticipant(participantData);

        const eventData = await fetchEventDetails(participantData.event_id);
        setEventDetails(eventData);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [queue_id]);

  // Fetch lap times every 30 seconds
  useEffect(() => {
    const fetchLapTimesData = async () => {
      try {
        const response = await fetchLapTimes(queue_id);
        const validLapTimes = response.data.filter(lap => lap.lapTimeInSeconds !== "0"); // Filter out lap times with value 0
        setLapTimes(validLapTimes);
      } catch (error) {
        console.error('Error fetching lap times:', error);
      }
    };

    // Fetch lap times initially and set interval for every 30 seconds
    fetchLapTimesData();
    const interval = setInterval(fetchLapTimesData, 30000); // Refresh every 30 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [queue_id]);

  const handleActivateSuccess = (initialCountdown, turnFinished) => {
    setIsActivated(true);
    setCountdown(initialCountdown);
    setTurnFinished(turnFinished);
  };

  const formatCountdown = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  if (loading) {
    return <p>Loading data...</p>;
  }

  return (
    <div className="activate-participant h-screen flex items-center justify-center bg-gray-900">
      {!isActivated ? (
        <ActivateForm
          participant={participant}
          eventDetails={eventDetails}
          onActivateSuccess={handleActivateSuccess}
        />
      ) : (
        <div className="w-full max-w-lg">
          <div className="bg-white text-black p-4 rounded-lg mb-6">
            <h2 className="text-lg font-bold mb-2">Activado</h2>
            <p><strong>Registro:</strong> {participant.created_at ? formatDateToLocal(participant.created_at) : 'N/A'}</p>

            {/* Countdown Timer */}
            {countdown !== null ? (
              <p><strong>Tiempo restante:</strong> {turnFinished ? 'Turno Terminado' : `${formatCountdown(countdown)}`}</p>
            ) : (
              <p><strong>Activación:</strong> N/A</p>
            )}
          </div>

          {/* Lap Time Table */}
          <div className="bg-white text-black p-4 rounded-lg mb-6">
            <h2 className="text-lg font-bold mb-2">Lap Times</h2>
            <table className="w-full text-left border-collapse">
              <thead>
                <tr>
                  <th className="border-b-2 pb-2">Lap Number</th>
                  <th className="border-b-2 pb-2">Lap Time</th> {/* Now showing formatted timestamp */}
                </tr>
              </thead>
              <tbody>
                {lapTimes.map((lap) => (
                  <tr key={lap.id}>
                    <td className="border-b py-2">{lap.lapNumber}</td>
                    <td className="border-b py-2">{secondsToTimestamp(parseFloat(lap.lapTimeInSeconds))}</td> {/* Use secondsToTimestamp */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <LapTimeForm eventid={participant.event_id} participant={participant} queue_id={queue_id} />
        </div>
      )}
    </div>
  );
};

export default ActivateParticipant;
