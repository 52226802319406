import React from 'react';
import '../../assets/css/forms.css';

const NewUserForm = ({ formData, handleInputChange, handleSubmit }) => (
    <div className="form-container">
        <form onSubmit={handleSubmit}>
            <label htmlFor="first_name">Nombre</label>
            <input
                id="first_name"
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                required
                className={`form-control ${formData.first_name.error ? 'error' : ''}`}
            />
            {formData.first_name.error && <div className="error-message">{formData.first_name.error}</div>}

            <label htmlFor="last_name">Apellido</label>
            <input
                id="last_name"
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                required
                className={`form-control ${formData.last_name.error ? 'error' : ''}`}
            />
            {formData.last_name.error && <div className="error-message">{formData.last_name.error}</div>}

            <label htmlFor="phone">Número de teléfono</label>
            <input
                id="phone"
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
                className={`form-control ${formData.phone.error ? 'error' : ''}`}
            />
            {formData.phone.error && <div className="error-message">{formData.phone.error}</div>}

            <button type="submit" className="button">Registrar</button>
        </form>
    </div>
);

export default NewUserForm;
