import React from 'react';
import QRCode from 'qrcode.react';
import '../../assets/css/forms.css';

const RegistrationSuccess = ({ selectedUser, formData, registrationTime, qrUrl }) => {
    // Format the registration time to include AM/PM
    const formattedTime = new Date(registrationTime).toLocaleString('es-ES', {
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour12: true // This ensures AM/PM format
    });

    return (
        <div className='confirmation-container' style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>
            <h2 className='confirmation-title'>¡Registro exitoso!</h2>
            <p className='confirmation-message'>
                Por favor, guarde su código QR para el registro en el evento.
            </p>
            <div className='qr-container' style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <QRCode value={qrUrl} size={256} className='qr-code' />
            </div>
            <div className='confirmation-details' style={{ marginTop: '20px', fontSize: '16px', textAlign: 'center' }}>
                <p style={{ fontWeight: 'bold', color: 'red' }}>
                    {selectedUser ? `${selectedUser.first_name} ${selectedUser.last_name}` : `${formData.first_name} ${formData.last_name}`}
                </p>
                <p>{formData.email}</p>
                <p>{formattedTime}</p>
            </div>
        </div>
    );
};

export default RegistrationSuccess;
