import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import EmailForm from './EmailForm';
import UserSelectionForm from './UserSelectionForm';
import NewUserForm from './NewUserForm';
import RegistrationSuccess from './RegistrationSuccess';
import { checkUserExists, createUser, createQueue, fetchLapTimes } from './utils';
import ResultsTable from './ResultsTable'; // Import the new ResultsTable component
import '../../assets/css/forms.css';

const RegisterParticipant = () => {
    const { eventid } = useParams();
    const [email, setEmail] = useState('');
    const [userExists, setUserExists] = useState(null);
    const [multipleUsers, setMultipleUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        eventid: eventid
    });
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [qrUrl, setQrUrl] = useState('');
    const [registrationTime, setRegistrationTime] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showResults, setShowResults] = useState(false); // New state to show results
    const [lapTimes, setLapTimes] = useState([]); // Store lap times

    const handleEmailSubmit = async (event) => {
        event.preventDefault();
        const data = await checkUserExists(email);
        setFormData(prev => ({ ...prev, email })); // Ensure email is set here
        if (data.length > 0) {
            if (data.length === 1) {
                setSelectedUser(data[0]);
                setUserExists(true);
            } else {
                setMultipleUsers(data);
                setUserExists(true);
            }
        } else {
            setUserExists(false);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        try {
            let userId = selectedUser ? selectedUser.id : null;
            if (!userId) {
                userId = await createUser(formData);
            }
            const qrCodeUrl = await createQueue(userId, eventid);
            setQrUrl(qrCodeUrl);
            setRegistrationSuccess(true);
            setRegistrationTime(new Date().toLocaleString());
        } catch (error) {
            let errorMsg = 'Ocurrió un error al registrar el participante. Por favor, inténtelo de nuevo.';
            if (error.response && error.response.data && error.response.data.message) {
                errorMsg = error.response.data.message;
            }
            setErrorMessage(errorMsg);
        }
    };

    const fetchAndShowLapTimes = async () => {
        try {
            const times = await fetchLapTimes(eventid, selectedUser.id);
            setLapTimes(times);
            setShowResults(true); // Show results page
        } catch (error) {
            setErrorMessage('Error al obtener los tiempos de vuelta.');
        }
    };

    return (
        <div className='w-full h-full flex flex-col justify-center items-center'>
            {showResults ? (
                <ResultsTable lapTimes={lapTimes} /> // Render the ResultsTable component
            ) : (
                <>
                    {userExists === null && (
                        <div className='confirmation-container'>
                            <EmailForm
                                email={email}
                                setEmail={setEmail}
                                handleEmailSubmit={handleEmailSubmit}
                            />
                        </div>
                    )}
                    {registrationSuccess ? (
                        <div className='confirmation-container'>
                            <RegistrationSuccess
                                selectedUser={selectedUser}
                                formData={formData}
                                registrationTime={registrationTime}
                                qrUrl={qrUrl}
                            />
                        </div>
                    ) : (
                        <>
                            {userExists === true && multipleUsers.length > 1 && (
                                <div className='confirmation-container'>
                                    <UserSelectionForm
                                        multipleUsers={multipleUsers}
                                        handleUserSelect={handleUserSelect}
                                        handleSubmit={handleSubmit}
                                    />
                                </div>
                            )}
                            {userExists === true && selectedUser && (
                                <div className='confirmation-container'>
                                    <h1 className='text-center font-formula1-display-regular text-3xl text-black mb-6'>
                                        Inscripción Simulador
                                    </h1>
                                    <p className='font-formula1-display-regular text-lg text-gray-700 mb-8'>
                                        Bienvenido, <strong>{selectedUser.first_name} {selectedUser.last_name}</strong>.
                                    </p>
                                    {errorMessage ? (
                                        <p className="error-message" style={{ color: 'red', marginBottom: '20px' }}>
                                            {errorMessage}
                                        </p>
                                    ) : null}
                                    
                                    {/* Buttons in horizontal alignment */}
                                    <div className='button-group-horizontal'>
                                        <button className='custom-button' onClick={handleSubmit}>Registrar</button>
                                        <button className='custom-button' onClick={fetchAndShowLapTimes}>Resultados</button>
                                    </div>
                                </div>
                            )}
                            {userExists === false && (
                                <div className='confirmation-container'>
                                    <NewUserForm
                                        formData={formData}
                                        handleInputChange={handleInputChange}
                                        handleSubmit={handleSubmit}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default RegisterParticipant;
