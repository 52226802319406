import React, { useState, useEffect } from 'react';
import { activateParticipant } from '../../helper/apiHelpers';
import { formatDateToLocal } from '../../helper/utils';

const ActivateForm = ({ participant, eventDetails, onActivateSuccess }) => {
  const [password, setPassword] = useState('');
  const [activationStatus, setActivationStatus] = useState('');
  const [countdown, setCountdown] = useState(null);
  const [turnFinished, setTurnFinished] = useState(false);

  // Start the countdown as soon as it's initialized
  useEffect(() => {
    if (countdown !== null && countdown > 0) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(interval); // Cleanup interval when unmounted or countdown reaches 0
    }
  }, [countdown]);

  // Format countdown to m:ss
  const formatCountdown = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleActivate = async () => {
    try {
      const response = await activateParticipant(participant.id, password);
      if (response.success) {
        setActivationStatus('Activación exitosa');

        // Start countdown if eventDetails and max_time are available
        if (eventDetails && eventDetails.max_time) {
          setCountdown(eventDetails.max_time);
          setTurnFinished(false);

          // Pass countdown and turnFinished status to parent
          onActivateSuccess(eventDetails.max_time, turnFinished);
        } else {
          console.error('Event details or max_time are missing');
        }
      } else {
        setActivationStatus('Activación fallida: ' + response.message);
      }
    } catch (error) {
      setActivationStatus(`Error: ${error.message}`);
    }
  };

  const handleInputChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="activate-form bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-lg font-bold mb-4">
          Activar a {participant.first_name} {participant.last_name}
        </h2>
        <p><strong>Registro:</strong> {participant.created_at ? formatDateToLocal(participant.created_at) : 'N/A'}</p>

        {countdown !== null ? (
          <p><strong>Tiempo restante:</strong> {turnFinished ? 'Turno Terminado' : `${formatCountdown(countdown)}`}</p>
        ) : (
          <>
            <input
              type="password"
              value={password}
              onChange={handleInputChange}
              placeholder="Ingrese la contraseña"
              className="bg-gray-200 text-black py-2 px-4 rounded-md mb-4 w-full"
            />
            <button
              onClick={handleActivate}
              className="bg-green-500 text-white py-2 px-4 rounded-md hover:scale-105 w-full"
            >
              Activar
            </button>
            {activationStatus && <p className="mt-4 text-red-500">{activationStatus}</p>}
          </>
        )}
      </div>
    </div>
  );
};

export default ActivateForm;
