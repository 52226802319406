import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import {
  fetchFastestLaps,
  fetchQueueEntries,
  fetchEventDetails,
  putQueue,
  postQueue,
} from "./helper/apiHelpers";
import "./assets/css/style.css";
import "./assets/css/all.min.css";
import "./assets/css/default.css";
import "./assets/css/fontawesome.css";
import "./assets/css/responsive.css";

import { TeamsContext } from "./context/TeamsContext"; // Import the TeamsContext

// Function to format time from seconds to MM:SS.mmm format
function formatTime(seconds) {
  const totalSeconds = parseFloat(seconds);
  const minutes = Math.floor(totalSeconds / 60);
  const secondsLeft = totalSeconds - minutes * 60;
  const formattedSeconds = secondsLeft.toFixed(3);
  return `${minutes}:${formattedSeconds.padStart(6, "0")}`;
}

// Helper function to get the first name and first last name
const getFirstName = (name) => {
  if (!name) return "N/A";
  return name.split(" ")[0]; // Get only the first name
};

const getFirstLastName = (name) => {
  if (!name) return "N/A";
  return name.split(" ")[0]; // Get only the first part of the last name
};

const FastestLap = () => {
  const { eventid } = useParams();
  const [fastestLaps, setFastestLaps] = useState([]);
  const [queueEntries, setQueueEntries] = useState([]);
  const [eventDetails, setEventDetails] = useState(null);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const { teams } = useContext(TeamsContext); // Use the global teams context

  useEffect(() => {
    const loadData = async () => {
      const laps = await fetchFastestLaps(eventid);
      const queue = await fetchQueueEntries(eventid);
      const event = await fetchEventDetails(eventid);

      setFastestLaps(laps);
      setQueueEntries(queue);
      setEventDetails(event);
    };

    loadData();

    const interval = setInterval(loadData, 30000); // Refresh data every 30 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [eventid]);

  const handleEntryClick = (entry) => {
    setSelectedEntry(entry);
  };

  const handleCloseBox = () => {
    setSelectedEntry(null);
  };

  const handleActivate = () => {
    const url = `/activate/${eventid}/${selectedEntry.id}`;
    window.open(url, "_blank");
    handleCloseBox();
  };

  const handleSendToLast = async () => {
    if (selectedEntry) {
      const putPayload = { status: 3 };
      const postPayload = {
        status: 1,
        participant_id: selectedEntry.participant_id,
        event_id: eventid,
        team_id: selectedEntry.team_id,
        orderid: 500,
      };

      await putQueue(selectedEntry.id, putPayload); 
      await postQueue(postPayload);

      handleCloseBox();
      const updatedQueueEntries = await fetchQueueEntries(eventid);
      setQueueEntries(updatedQueueEntries);
    }
  };

  const handleRemoveFromQueue = async () => {
    if (selectedEntry) {
      const putPayload = { status: 3 };

      await putQueue(selectedEntry.id, putPayload);

      handleCloseBox();
      const updatedQueueEntries = await fetchQueueEntries(eventid);
      setQueueEntries(updatedQueueEntries);
    }
  };

  const getTeamDetails = (teamId) => {
    return teams.find((team) => team.team_id === teamId) || {};
  };

  return (
    <div className="grid grid-cols-5 h-full font-formula1-display-regular mx-8 my-2">
      <div className="col-span-3">
      <div className="flex mb-12">
  <img
    className="gplogo mr-4"
    src="/assets/img/gp-esports-ro.png"
    alt="Event Logo"
  />
  <img
    className="sponsorlogo"
    src={eventDetails?.event_logo || "/assets/img/gp-esports-ro.png"}
    alt="Sponsor Logo"
  />
</div>

        <div className="w-full mt-4 p-4 border-4 border-[#B4B4B6] rounded-lg bg-[#101117]">
          <h2 className="text-white font-formula1-display-black mb-2 text-xl md:text-xl">
            {eventDetails?.event_description ||
              "FORMULA 1 GP ESPORTS Australian Gran Prix 2024"}
          </h2>
          <h1 className="text-[#afafad] text-2xl font-formula1-display-semi mb-4">
            MEJORES TIEMPOS
          </h1>
          <div>
            <div className="w-full h-full text-white">
              {fastestLaps.map((lap, index) => {
                const teamDetails = getTeamDetails(lap.teamid);
                const teamClass = teamDetails.team_name
                  ? `${teamDetails.team_name.replace(/\s+/g, "")}-icon-bg`
                  : "Ferrari-icon-bg";
                const teamLogo =
                  teamDetails.small_logo || "https://gpesportsrd.com/images/teamlogobig/ic-porsche.png";

                return (
                  <div
                    key={index}
                    className={`formula__box__single__item ${
                      index === 0 ? "active" : "formula__box--backend"
                    }`}
                    style={
                      index === 0
                        ? { backgroundImage: "url(/assets/img/actv--bg.png)" }
                        : {}
                    }
                  >
                    <div className="formula__item__fx">
                      <div className="formula__item__top">
                        <div className="formula__number">
                          <h5>{index + 1}</h5>
                        </div>
                        <div className="player__name">
                          <div className="name">
                            <h4>
                              <span>{getFirstName(lap?.participant?.first_name)}</span>
                              {getFirstLastName(lap?.participant?.last_name).toUpperCase()}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="brand__name">
                        <a href="#">
                          <span className={teamClass}>
                            <img
                              src={teamLogo}
                              alt={teamDetails.team_name || "Team Logo"}
                            />
                          </span>
                          {teamDetails.team_name?.toUpperCase() || "PORSCHE 992 GT3 CUP"}
                        </a>
                      </div>
                      <div className="time">
                        <h5>{formatTime(lap.lapTimeInSeconds)}</h5>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="grid col-span-2 ps-16 pe-4 content-between gap-12">
        <div className="flex justify-center items-center">
          <div
            className="bg-white p-2"
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: 182,
              width: "100%",
            }}
          >
            <QRCode
              size={358}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={`https://tt.gpesportsrd.com/register/${eventid}`}
              viewBox={`0 0 256 256`}
            />
          </div>
        </div>
        <div className="text-white text-2xl h-4 flex justify-center items-center">
          AQUI VA EL IFRAME
        </div>
        <div className="grid grid-rows-4 w-full text-white items-start my-0 py-0">
          {queueEntries.length > 0 ? (
            queueEntries.map((entry, index) =>
              index === 0 ? (
                <div
                  key={index}
                  className="player__detials__single player__bakend player__Active"
                  onClick={() => handleEntryClick(entry)}
                >
                  <div className="player--img player__img--backend">
                    <img
                      src="/assets/img/player--img.png"
                      alt="Participant"
                    />
                  </div>
                  <div className="player__ct__flag player__flag--backend">
                    <img
                      src="/assets/img/player--ct--flag.png"
                      alt="Country Flag"
                    />
                  </div>
                  <div className="player__number">
                    <span>{index + 1}</span>
                  </div>
                  <div className="palyer__content">
                    <h4>{getFirstName(entry.first_name?.toUpperCase()) || "N/A"}</h4>
                    <h2>{getFirstLastName(entry.last_name?.toUpperCase()) || "N/A"}</h2>
                    <p>{entry.team_id || "N/A"}</p>
                  </div>
                </div>
              ) : (
                <div
                  key={index}
                  className="player__detials__single player__bakend"
                  onClick={() => handleEntryClick(entry)}
                >
                  <div className="player__number">
                    <span>{index + 1}</span>
                  </div>
                  <div className="palyer__content">
                    <h4>{getFirstName(entry.first_name?.toUpperCase()) || "N/A"}</h4>
                    <h2>{getFirstLastName(entry.last_name?.toUpperCase()) || "N/A"}</h2>
                    <p>{entry.team_id || "N/A"}</p>
                  </div>
                </div>
              )
            )
          ) : (
            <div className="text-white text-center">No data available</div>
          )}
        </div>
      </div>
      {selectedEntry && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-[#12131b] p-4 rounded-lg text-white relative">
            <h3 className="text-lg mb-4">
              {getFirstName(selectedEntry.first_name || "N/A")}{" "}
              {getFirstLastName(selectedEntry.last_name || "N/A")}
            </h3>
            <div className="flex gap-4">
              <button
                className="bg-green-500 p-2 rounded-lg"
                onClick={handleActivate}
              >
                Activate
              </button>
              <button
                className="bg-yellow-500 p-2 rounded-lg"
                onClick={handleSendToLast}
              >
                Send to Last
              </button>
              <button
                className="bg-red-500 p-2 rounded-lg"
                onClick={handleRemoveFromQueue}
              >
                Remove
              </button>
            </div>
            <button
              className="absolute top-2 right-2 text-gray-400"
              onClick={handleCloseBox}
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FastestLap;
