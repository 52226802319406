import React from 'react';
import '../../assets/css/forms.css';

const EmailForm = ({ email, setEmail, handleEmailSubmit }) => (
    <div className="form-container">
        <h1 className="text-center mb-10 font-formula1-display-regular text-3xl text-black">Inscripción Simulador</h1>
        <form onSubmit={handleEmailSubmit}>
            <label htmlFor="email">Dirección de correo electrónico</label>
            <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={`form-control ${email.error ? 'error' : ''}`}
                style={{ fontFamily: 'Arial, sans-serif' }}  // Apply standard font here
            />
            {email.error && <div className="error-message">{email.error}</div>}
            <button type="submit" className="button">Continuar</button>
        </form>
    </div>
);

export default EmailForm;
