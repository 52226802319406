export const checkUserExists = async (email) => {
    try {
        const response = await fetch(`https://api4.gpesportsrd.com/timeattack/users?email=${encodeURIComponent(email)}`);
        return await response.json();
    } catch (error) {
        console.error('Error checking user:', error);
        throw error;
    }
};

export const createUser = async (user) => {
    try {
        const response = await fetch('https://api4.gpesportsrd.com/timeattack/users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user)
        });
        const result = await response.json();
        return result.data.id;
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};

export const createQueue = async (participantId, eventId) => {
    try {
        const response = await fetch('https://api4.gpesportsrd.com/timeattack/queue', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ participant_id: participantId, event_id: eventId })
        });

        const result = await response.json();

        if (response.status === 400 && result.message) {
            const error = new Error(result.message);
            error.response = { data: result }; // Attach full response to the error
            throw error;
        }

        return `https://tt.gpesportsrd.com/activate/${eventId}/${result.data.id}`;
    } catch (error) {
        console.error('Error creating queue:', error);
        throw error; // Re-throw the error so it can be handled in the component
    }
};
export const fetchLapTimes = async (eventId, participantId) => {
    try {
        const response = await fetch(`https://api4.gpesportsrd.com/timeattack/laptime?eventId=${eventId}&participantId=${participantId}`);
        const result = await response.json();

        if (response.ok && result.data) {
            return result.data; // Return the lap times
        } else {
            throw new Error(result.message || 'Error fetching lap times');
        }
    } catch (error) {
        console.error('Error fetching lap times:', error);
        throw error;
    }
};


export const formatLapTime = (timeInSeconds) => {
    if (!timeInSeconds || isNaN(timeInSeconds)) return "N/A";
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = (timeInSeconds % 60).toFixed(3);
    return minutes > 0 ? `${minutes}:${seconds}` : `${seconds} s`;
};