import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const SdkartingActivate = () => {
    const { eventid } = useParams(); // Access eventid from the URL
    const [orderID, setOrderID] = useState(''); // New state for Order ID

    const handleOrderIDChange = (event) => {
        setOrderID(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Check Order ID
            const queueResponse = await fetch(`https://api4.gpesportsrd.com/timeattack/queue?orderid=${encodeURIComponent(orderID)}`);
            const queueData = await queueResponse.json();
            console.log('Queue Data:', queueData);

            if (queueData.data && queueData.data.length > 0) {
                const queueEntry = queueData.data[0];
                if (queueEntry.status !== 1) {
                    alert('Este participante ya corrio, si entiende que es un error pase por caja');
                    return;
                }

                // Activate queue
                await fetch(`https://api4.gpesportsrd.com/timeattack/activate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        queueid: queueEntry.id,
                        password: 'sdkarting'
                    })
                });

                alert('Ya hemos activado el simulador, siga las instrucciones en pantalla');
            } else {
                alert('Order ID no encontrado.');
            }
        } catch (error) {
            console.error('Error processing request:', error);
        }
    };

    return (
        <div className='w-full h-full flex flex-col justify-center items-center mt-20'>
            <h1 className='mb-10 font-formula1-display-regular text-3xl text-white'>Activar Simulador</h1>
            <form className='w-full h-full flex flex-col justify-center items-center' onSubmit={handleSubmit}>
                <label className='font-formula1-display-regular text-xl text-white mb-4'>Ingrese numero de factura:</label>
                <div>
                    <input placeholder='Numero de factura' className='bg-white text-black py-2 px-4 rounded-md mr-2' type="text" value={orderID} onChange={handleOrderIDChange} required />
                    <button className='font-formula1-display-regular bg-white py-2 px-4 rounded-md hover:scale-105' type="submit">Activar</button>
                </div>
            </form>
        </div>
    );
};

export default SdkartingActivate;
