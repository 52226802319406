// src/helper/apiHelpers.js

import axios from 'axios';

const API_BASE_URL = 'https://api4.gpesportsrd.com/timeattack/queue';
const ACTIVATE_URL = 'https://api4.gpesportsrd.com/timeattack/activate';
const LAPTIME_URL = 'https://api4.gpesportsrd.com/timeattack/laptime';

// Participant Fetching Functions:

export const fetchParticipants = async (eventid) => {
  try {
    const response = await fetch(
      `https://api4.gpesportsrd.com/timeattack/participants?event_id=${eventid}`
    );
    const data = await response.json();
    return data.data || [];
  } catch (error) {
    console.error("Error fetching participants:", error);
    return [];
  }
};

export const fetchParticipantDetails = async (queueId) => {
  try {
    const response = await fetch(`https://api4.gpesportsrd.com/timeattack/queue?id=${queueId}`);
    if (!response.ok) {
      throw new Error('Turno no encontrado');
    }
    const data = await response.json();
    if (data.data && data.data.length > 0) {
      return data.data[0];
    } else {
      throw new Error('Turno no encontrado');
    }
  } catch (error) {
    console.error('Error fetching participant details:', error);
    throw error;
  }
};

export const fetchUserDetails = async (participantId) => {
  try {
    const response = await fetch(`https://api4.gpesportsrd.com/timeattack/users?id=${participantId}`);
    if (!response.ok) {
      throw new Error('Error encontrando usuario');
    }
    const userData = await response.json();
    if (userData && userData.length > 0) {
      return userData[0];
    } else {
      throw new Error('Usuario no encontrado');
    }
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }
};

// Activation and Lap Time Submission Functions:

export const activateParticipant = async (queueId, password) => {
  try {
    const response = await fetch(ACTIVATE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        queueid: parseInt(queueId, 10),
        password: password,
      }),
    });

    if (response.ok) {
      return { success: true };
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
  } catch (error) {
    console.error('Error activating participant:', error);
    throw error;
  }
};

export const submitLapTime = async (lapData) => {
  try {
    const response = await fetch(LAPTIME_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(lapData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    return { success: true };
  } catch (error) {
    console.error('Error submitting lap time:', error);
    throw error;
  }
};

// Event and Queue Data Fetching:

export const fetchFastestLaps = async (eventid) => {
  try {
    const response = await fetch(
      `https://api4.gpesportsrd.com/timeattack/laptime/fastlaps?eventId=${eventid}`
    );
    const data = await response.json();
    return data && data.data ? data.data : [];
  } catch (error) {
    console.error("Error fetching fastest laps: ", error);
    return [];
  }
};

export const fetchQueueEntries = async (eventid) => {
  try {
    const response = await fetch(
      `https://api4.gpesportsrd.com/timeattack/queue/current?eventid=${eventid}`
    );
    const data = await response.json();
    return data && data.data ? data.data.slice(0, 4) : [];
  } catch (error) {
    console.error("Error fetching queue entries: ", error);
    return [];
  }
};

export const fetchEventDetails = async (eventid) => {
  try {
    const response = await fetch(
      `https://api4.gpesportsrd.com/timeattack/events?id=${eventid}`
    );
    const data = await response.json();
    return data || null;
  } catch (error) {
    console.error("Error fetching event details: ", error);
    return null;
  }
};

export const fetchTeams = async () => {
  try {
    const response = await fetch('https://api4.gpesportsrd.com/timeattack/teams');
    const data = await response.json();
    return data || [];
  } catch (error) {
    console.error("Error fetching teams:", error);
    return [];
  }
};

// Generic function to update a queue entry by ID
export const putQueue = async (queueId, payload) => {
  try {
    await axios.put(`${API_BASE_URL}/${queueId}`, payload);
    console.log('Queue entry updated successfully');
  } catch (error) {
    console.error('Error updating queue entry:', error);
    throw error;
  }
};

// Generic function to create a new queue entry
export const postQueue = async (payload) => {
  try {
    await axios.post(`${API_BASE_URL}`, payload);
    console.log('New queue entry created successfully');
  } catch (error) {
    console.error('Error creating queue entry:', error);
    throw error;
  }
};

// Remove a participant from the queue by setting their status to 3
export const removeFromQueue = async (queueId) => {
  try {
    await putQueue(queueId, { status: 3 });
    console.log(`Participant with queue ID ${queueId} removed from queue successfully`);
  } catch (error) {
    console.error(`Error removing participant from queue:`, error);
    throw error;
  }
};


export const fetchLapTimes = async (queueId) => {
  try {
    const response = await axios.get(`https://api4.gpesportsrd.com/timeattack/laptime?queueId=${queueId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching lap times:', error);
    throw error;
  }
};