import React from 'react';
import '../../assets/css/forms.css';

const UserSelectionForm = ({ multipleUsers, handleUserSelect, handleSubmit }) => (
    <form className='form-container' onSubmit={handleSubmit}>
        <p>Seleccione un usuario asociado con este correo electrónico:</p>
        {multipleUsers.map((user, index) => (
            <div className='mt-2' key={index}>
                <input
                    className='mr-2'
                    type="radio"
                    id={`user-${index}`}
                    name="user"
                    value={JSON.stringify(user)}
                    onChange={() => handleUserSelect(user)}
                />
                <label htmlFor={`user-${index}`}>{user.first_name} {user.last_name} ({user.email})</label>
            </div>
        ))}
        <button className='button mt-4' type="submit">Confirmar selección</button>
    </form>
);

export default UserSelectionForm;
