import React from 'react';
import { formatLapTime } from './utils';  // Import the helper function

const ResultsTable = ({ lapTimes }) => {
    // Sort lap times by lap number
    const sortedLapTimes = [...lapTimes].sort((a, b) => a.lapNumber - b.lapNumber);

    return (
        <div className="results-container">
            <h1 className="text-center font-formula1-display-regular text-3xl text-black mb-6">
                Resultados de Vueltas
            </h1>
            <table className="lap-table">
                <thead>
                    <tr>
                        <th>Vuelta</th>
                        <th>Tiempo (segundos)</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedLapTimes.map((lap, index) => (
                        <tr key={index}>
                            <td>{lap.lapNumber}</td> {/* Just show the lap number */}
                            <td>{formatLapTime(lap.lapTimeInSeconds)}</td> {/* Format the time */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ResultsTable;
