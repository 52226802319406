import React, { createContext, useState, useEffect } from 'react';
import { fetchTeams } from '../helper/apiHelpers'; // Assume you have this helper to fetch teams

export const TeamsContext = createContext();

export const TeamsProvider = ({ children }) => {
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        const loadTeams = async () => {
            try {
                const data = await fetchTeams();
                setTeams(data);
            } catch (error) {
                console.error("Error fetching teams:", error);
            }
        };
        loadTeams();
    }, []);

    return (
        <TeamsContext.Provider value={{ teams }}>
            {children}
        </TeamsContext.Provider>
    );
};
